<template>
  <div>
    <div style="margin-bottom:15px;display:flex;justify-content:space-between;align-items:center;">
      <label class="label">Add gallery to your page</label>
    </div>
    <div v-if="content.styleSettings" style="margin-bottom: 15px;">
        <div style="margin-bottom: 15px;">
          <v-btn color="primary" @click="closeSettings" outlined small><v-icon>mdi-chevron-left</v-icon>Back to gallery settings</v-btn>
        </div>
        <div>
            <div class="control" >
                <label class="label" style="font-weight: bold;font-size:20px;">Image settings</label>
            </div>
            <div>
                <label class="label">
                    Change Height <b>{{ content.styles.img_height }}px</b>
                </label>
                <div class="control">
                    <div class="slider">
                        <v-slider
                                hide-details
                                :max="500"
                                :min="50"
                                v-model="content.styles.img_height"
                        ></v-slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!content.styleSettings">
      <div class="control mb-6">
        <vue-dropzone
          :id="`upload_detail`"
          :ref="`upload_detail`"
          :options="dropzoneOptionsListingDetailMenu"
          :destroyDropzone="false"
          @vdropzone-mounted="mountDropzone"
          @vdropzone-sending="customEvent"
          @vdropzone-removed-file="(file) => handleRemove(file)"
          @vdropzone-success="(file) => handleSuccess(file)"
        >
        </vue-dropzone>
      </div>
      <div v-if="content.img.length > 1">
        <label class="label">You can change image order</label>
        <draggable
            :options="{disabled: isMobile}"
          style="display: flex; margin-bottom: 10px"
          :list="content.img"
        >
          <div
            v-for="(img, index) in content.img"
            :key="img"
            style="margin-right: 15px; position: relative"
          >
            <div
              style="
                border: 2px solid #007db5;
                color: #007db5;
                padding: 3px 6px;
                font-weight: bold;
                background: #fafafa;
                position: absolute;
                top: 0;
                left: 0;
              "
            >
              {{ index + 1 }}
            </div>
            <img :src="img" alt="" style="width: 150px; height: 100px" />
          </div>
        </draggable>
      </div>
      <div class="item">
        <div class="control" style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="margin-right: 15px;display: block;margin-bottom: 0">Video</label>
          <v-menu offset-y open-on-hover bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="margin-right: 15px;" color="primary" dark outlined small v-bind="attrs" v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title
                ><label for="gallery" style="cursor: pointer"
                >Browse</label>
                  <v-file-input
                      id="gallery"
                      accept="video/*"
                      show-size
                      ref="uploader"
                      prepend-icon=""
                      placeholder="Upload video"
                      outlined
                      style="display: none"
                      hide-details
                      dense
                      @change="onFileChange"></v-file-input>
                </v-list-item-title
                >
              </v-list-item>
              <v-list-item  link @click="openModalVideoLink">
                <v-list-item-title style="cursor: pointer"
                >Link</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-if="content.video">
            Link: {{content.video}}
          </div>
          <div v-if="content.video">
            Name: {{formatName(content.video)}}
          </div>
        </div>
      </div>
      <div class="control" style="margin-bottom: 15px;">
        <Editor
          api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
          :init="options"
          v-model="content.full_desc"
        ></Editor>
      </div>
      <FormOrder :item="content" :menu="menu"/>
    </div>
    <PopupConfirm
      ref="PopupConfirm"
      @confirm="confirmCopy"
      :title="`Are you sure about copy content from ${defaultLangName} to ${currentLangName}`"
    />
    <PopupCrop title='Change image before upload?' ref="PopupCrop" @confirm="openEditor" @save="saveImg" @close="closeCrop"/>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="video_link_modal" max-width="700" eager @click:outside="closeModalVideoLink">
      <v-card>
        <v-btn class="popup__close" small icon @click="closeModalVideoLink">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Add video link
        </v-card-title>
        <v-card-text>
          <div class="card_body">
            <div class="card_body_item" style="display: flex;margin-bottom:15px;align-items: center">
              <label class="label" style="display:block;margin-bottom: 0;width:75px;">Link</label>
              <v-text-field placeholder="Link" outlined dense hide-details v-model.number="video_link">
              </v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
          <v-btn @click="saveModalVideoLink" outlined small color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Cropper from "cropperjs";
import vueDropzone from "vue2-dropzone";
import FormOrder from "@/components/Constructor/Menu/FormOrder";
import Editor from "@tinymce/tinymce-vue";
import draggable from "vuedraggable";
import PopupConfirm from "@/components/Popup/PopupConfirm";
import { mapState } from "vuex";
import { defaultFormMenuItem } from "@/store/landing";
import Compressor from "compressorjs";
export default {
  name: "typeGallery",
  props: ["item", "content"],
  components: {
    FormOrder,
    vueDropzone,
    draggable,
    PopupConfirm,
    PopupCrop:() => import('@/components/Popup/PopupCrop')
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  mounted() {
  },
  data() {
    return {
      video_link_modal:false,
      video_link:"",
      isMobile:false,
      styles: false,
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                    '<a href="' +
                    api.getData().button_href +
                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                    api.getData().button_style +
                    '--text" rel="' +
                    api.getData().button_rel +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    api.getData().button_label +
                    "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      dropzoneOptionsListingDetailMenu: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        parallelUploads: 1,
        uploadMultiple: false,
        dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        init: function() {
          this.handleFiles = function(files) {
            var file, _i, _len, _results;
            _results = [];
            for (_i = 0, _len = files.length; _i < _len; _i++) {
              file = files[_i];
              _results.push(this.addFile(file));
              break;
            }
            return _results;
          };
          this._addFilesFromItems = function(items) {
            var entry, item, _i, _len, _results;
            _results = [];
            for (_i = 0, _len = items.length; _i < _len; _i++) {
              item = items[_i];
              if ((item.webkitGetAsEntry != null) && (entry = item.webkitGetAsEntry())) {
                if (entry.isFile) {
                  _results.push(this.addFile(item.getAsFile()));
                } else if (entry.isDirectory) {
                  _results.push(this._addFilesFromDirectory(entry, entry.name));
                } else {
                  _results.push(void 0);
                }
              } else if (item.getAsFile != null) {
                if ((item.kind == null) || item.kind === "file") {
                  _results.push(this.addFile(item.getAsFile()));
                } else {
                  _results.push(void 0);
                }
              } else {
                _results.push(void 0);
              }
              break;
            }
            return _results;
          };
        },
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          } else {
            this.compress(file);
          }
        },
      },
    };
  },
  methods: {
    formatName(video_link){
      return video_link.slice(video_link.lastIndexOf('/')+1);
    },
    saveModalVideoLink(){
      if(this.content.video){
        this.content.video = "";
      }
      this.content.video_link = this.video_link.split('').join('');
      this.video_link_modal = false;
    },
    closeModalVideoLink(){
      this.video_link_modal = false;
    },
    openModalVideoLink(){
      if(this.content.video){
        this.video_link = this.content.video.split('').join('');
      }else{
        this.video_link = "";
      }
      this.video_link_modal = true;
    },
    closeSettings(){
      this.content.styleSettings = false;
    },
    async onFileChange(file){
      try{
        if(file){
          let formData = new FormData();
          formData.append('hotel_id',+this.$route.params.id);
          formData.append('file',file);
          const response = await this.$axios.post('upload-video',formData);
          this.video_link = "";
          this.content.video_link = "";
          this.content.video = response.data;
        }
      }catch(e){
        console.log(e);
      }
    },
    openEditor(file){
      let editor = document.createElement("div");
      editor.setAttribute("id", "editor");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      let slider = document.createElement("div");
      slider.style.position = "absolute";
      slider.style.bottom = "20px";
      slider.style.left = "24px";
      slider.style.zIndex = "10";
      slider.innerHTML +=
          ' <div class="slider-component"><span class="listingHeight" style="color:#fff;font-size:18px; font-family: Inter, sans-serif;">Change height ' +
          this.content.styles.img_height +
          ' px</span><div class="slidecontainer"><input ref="input" type="range" style="width:300px;" min="90" max="400" value="' +
          this.content.styles.img_height +
          '" class="slider"></div></div>';
      let sliderInput = slider.getElementsByClassName("slider")[0];
      sliderInput.addEventListener("input", (e) => {
        this.content.styles.img_height = e.target.value;
        let ratio = 330 / +this.content.styles.img_height;
        let listingHeight =
            slider.getElementsByClassName("listingHeight")[0];
        listingHeight.innerHTML =
            "Change height " + this.content.styles.img_height + " px";
        cropper.setAspectRatio(ratio);
        cropper.previews[0].cropperPreview.width = 330;
        cropper.previews[0].cropperPreview.height =
            +this.content.styles.img_height;
      });
      editor.appendChild(slider);
      buttonCancel.addEventListener("click", () => {
        file.imgRemove = this.content.img.length - 1;
        this.$refs.upload_detail.dropzone.removeFile(file);
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.imgRemove = this.content.img.length - 1;

        newFile.name = file.name;
        const candidate = this.$refs.upload_detail.dropzone.files.find(x => x.status === 'queued');
        if(candidate){
          this.$refs.upload_detail.dropzone.removeFile(candidate);
        }
        this.$refs.upload_detail.dropzone.addFile(newFile);
        this.$refs.upload_detail.dropzone.processQueue();
        this.$refs.upload_detail.dropzone[
            "previewsContainer"
            ].children[
            this.$refs.upload_detail.dropzone.files.length
            ].children[0].children[0].src = canvas.toDataURL(file.type);
        this.$refs.upload_detail.dropzone[
            "previewsContainer"
            ].children[
            this.$refs.upload_detail.dropzone.files.length
            ].children[0].style.borderRadius = 0;
        this.$refs.upload_detail.dropzone[
            "previewsContainer"
            ].children[
            this.$refs.upload_detail.dropzone.files.length
            ].children[1].style.background = "none";
        document.body.removeChild(editor);
        cropper.destroy();
        localStorage.setItem("update", "true");
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.content.img.push("1");
      let cropper = new Cropper(image, {
        preview: `.preview_detail`,
        aspectRatio: 330 / +this.content.styles.img_height,
        responsive: false,
      });
    },
    async saveImg(file){
      file.cropped = true;
      const candidate = this.$refs.upload_detail.dropzone.files.find(x => x.status === 'queued');
      if(candidate){
        this.$refs.upload_detail.dropzone.removeFile(candidate);
      }
      this.$refs.upload_detail.dropzone.addFile(file);
      this.$refs.upload_detail.dropzone.processQueue();
      this.$refs.upload_detail.dropzone["previewsContainer"].children[
          this.$refs.upload_detail.dropzone.files.length
          ].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.upload_detail.dropzone["previewsContainer"].children[
          this.$refs.upload_detail.dropzone.files.length
          ].children[0].style.borderRadius = 0;
      this.$refs.upload_detail.dropzone["previewsContainer"].children[
          this.$refs.upload_detail.dropzone.files.length
          ].children[1].style.background = "none";
    },
    closeCrop(){

    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          if(this.$store.state.isMobile){
            this.saveImg(result)
          }else{
            this.$refs.PopupCrop.open(result)
          }
        },
        error(e) {
          console.log(e);
        }
      })
    },
    toggleStyles() {
      this.styles = !this.styles;
    },
    copyLang() {
      this.$refs.PopupConfirm.open();
    },
    confirmCopy(){
      this.item.content[this.landing.current_lang][12] = JSON.parse(JSON.stringify(this.item.content[this.landing.advanced.default_lang][12]));
      this.forceRerender();
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    mountDropzone() {
      for (let imgInd = 0; imgInd < this.content.img.length; imgInd++) {
        let mockFile = {
          accepted: true,
          cropped: true,
          imgRemove: imgInd,
          kind: "image",
          ...this.content,
        };
        if (this.$refs.upload_detail.dropzone) {
          if (
            !this.$refs.upload_detail.dropzone.files.includes(
              this.content.img[imgInd]
            )
          ) {
            if (
              this.$refs.upload_detail.dropzone.files.length !==
              this.content.img.length
            ) {
              this.$refs.upload_detail.manuallyAddFile(
                mockFile,
                this.content.img[imgInd]
              );
              this.$refs.upload_detail.dropzone["previewsContainer"].children[
                imgInd + 1
              ].children[0].children[0].src = this.content.img[imgInd];
              this.$refs.upload_detail.dropzone["previewsContainer"].children[
                imgInd + 1
              ].children[0].style.borderRadius = 0;
              this.$refs.upload_detail.dropzone["previewsContainer"].children[
                imgInd + 1
              ].children[1].style.background = "none";
              this.$refs.upload_detail.dropzone.emit("complete", mockFile);
            }
          }
        }
      }
    },
    customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    async handleRemove(file) {
      if (file.imgRemove || file.imgRemove === 0) {
        this.content.img.splice(file.imgRemove, 1);
        let elements =
          this.$refs.upload_detail.$el.getElementsByClassName("dz-preview");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        this.$refs.upload_detail.dropzone.files = [];
        for (let imgInd = 0; imgInd < this.content.img.length; imgInd++) {
          let mockFile = {
            accepted: true,
            cropped: true,
            imgRemove: imgInd,
            notRemove: true,
            kind: "image",
            ...this.content,
          };
          if (this.$refs.upload_detail.dropzone) {
            if (
              !this.$refs.upload_detail.dropzone.files.includes(
                this.content.img[imgInd]
              )
            ) {
              if (
                this.$refs.upload_detail.dropzone.files.length !==
                this.content.img.length
              ) {
                this.$refs.upload_detail.manuallyAddFile(
                  mockFile,
                  this.content.img[imgInd]
                );
                this.$refs.upload_detail.dropzone["previewsContainer"].children[
                  imgInd + 1
                ].children[0].children[0].src = this.content.img[imgInd];
                this.$refs.upload_detail.dropzone["previewsContainer"].children[
                  imgInd + 1
                ].children[0].style.borderRadius = 0;
                this.$refs.upload_detail.dropzone["previewsContainer"].children[
                  imgInd + 1
                ].children[1].style.background = "none";
                this.$refs.upload_detail.dropzone.emit("complete", mockFile);
              }
            }
          }
        }
      }
    },
    handleSuccess(file) {
      if(this.content.img.indexOf("1") >= 0){
        this.content.img.splice(
            this.content.img.indexOf("1"),
            1,
            file.xhr.response
        );
      }else{
        this.content.img.push(file.xhr.response);
      }
      let item = this.content;
      let elements =
          this.$refs.upload_detail.$el.getElementsByClassName(
              "dz-preview"
          );
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      this.$refs.upload_detail.dropzone.files = [];
      for (let imgInd = 0; imgInd < item.img.length; imgInd++) {
        let mockFile = {
          accepted: true,
          cropped: true,
          imgRemove: imgInd,
          notRemove: true,
          kind: "image",
          ...item,
        };
        if (this.$refs.upload_detail.dropzone) {
          if (
              !this.$refs.upload_detail.dropzone.files.includes(item.img[imgInd])
          ) {
            if (
                this.$refs.upload_detail.dropzone.files
                    .length !== item.img.length
            ) {

              this.$refs.upload_detail.manuallyAddFile(
                  mockFile,
                  item.img[imgInd]
              );
              this.$refs.upload_detail.dropzone[
                  "previewsContainer"
                  ].children[imgInd + 1].children[0].children[0].src =
                  item.img[imgInd];
              this.$refs.upload_detail.dropzone[
                  "previewsContainer"
                  ].children[imgInd + 1].children[0].style.borderRadius = 0;
              this.$refs.upload_detail.dropzone[
                  "previewsContainer"
                  ].children[imgInd + 1].children[1].style.background = "none";
              this.$refs.upload_detail.dropzone.emit(
                  "complete",
                  mockFile
              );
            }
          }
        }
      }
    },

    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("landing", ["menu"]),
    currentLangName() {
      return this.landing.advanced.langs.find(
        (x) => x.value === this.landing.current_lang
      ).name;
    },
    defaultLangName() {
      return this.landing.advanced.langs.find(
        (x) => x.value === this.landing.advanced.default_lang
      ).name;
    },
  },
};
</script>

<style scoped></style>
